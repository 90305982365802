/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {ArrowRightAlt, Height, Inventory2, Payments, PriceChange, Scale, Upgrade} from "@mui/icons-material";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";

const Formulario_Variante = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'center', marginBottom: -2, marginTop: 2}}>
                    <Typography>Imagen de variante</Typography>
                </Grid>

                <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-start'}}/>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoImagenCrop dato={'img'} {...props} ancho={1} alto={1}/>
                </Grid>

                <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-start'}}/>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory2} nombre={'Nombre de Variante'} dato={'nombre'}  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={Payments} nombre={'Valor Producto'} dato={'valor'}  {...props}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoDinero Icono={PriceChange} nombre={'Valor con descuento'} dato={'rebajado'}  {...props}

                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Datos de envio</Typography>
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Height} nombre={'Alto (cm)'} dato={'alto'} type={'number'}  {...props}
                                  requerido={'El alto del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={ArrowRightAlt} nombre={'Ancho (cm)'} dato={'ancho'} type={'number'}  {...props}
                                  requerido={'El Ancho del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Upgrade} nombre={'Fondo (cm)'} dato={'fondo'} type={'number'}  {...props}
                                  requerido={'El Fondo del producto es requerido*'}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>

                    <IngresoTexto Icono={Scale} nombre={'Peso (gr)'} dato={'peso'} type={'number'}  {...props}
                                  requerido={'El Peso del producto es requerido*'}
                    />
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Variante