import {obtenerDoc} from "../Servicios/BD/obtenerDoc";
import {
    enviarNotificacionMultilplesTokens
} from "../Modulo_Notificaciones/Funciones/enviarNotificacionMultilplesTokens";

export const enviarNotificacionAdmins = ({titulo, mensaje}) => {


    obtenerDoc('tokens', 'adminTokens').then((dox) => {
        if (dox.res) {
            let toks = dox.data.tokens

            console.log(toks)
            enviarNotificacionMultilplesTokens({arrToken: toks, mensaje: mensaje, titulo: titulo})
        }
    })


}