/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {theme} from "../../Tema";
import {Storefront, TaskAlt} from "@mui/icons-material";
import {obtenerDoc} from "../../Servicios/BD/obtenerDoc";
import {usuarioContext} from "../../App";
import Formulario_Tienda from "../Formularios/Formulario_Tienda";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_color.svg'
import {actualizarDoc} from "../../Servicios/BD/actualizarDoc";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {enviarNotificacionAdmins} from "../../Utilidades/enviarNotificacionAdmins";
import {irUrl} from "../../Utilidades/irUrl";
import {URLMARKETPLACE} from "../../Constantes";

const Seccion_Tienda = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const masSM = useMediaQuery(theme.breakpoints.up('md'))
    const cData = useContext(usuarioContext)
    const [tienda, setTienda] = useState({})
    const {setEntidad, props, obtenerEntidad} = useFormulario({valoresDefecto: {}})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({logo: logo})

    const guardarCambiosTienda = () => {
        obtenerEntidad().then((entidad) => {
            let obj = entidad
            obj.idPersona = cData.usuario.id
            obj.idEmpresaria = cData.usuario.idEmpresaria

            abrirCargador('Enviando datos para revision')
            subirImagenFile(entidad.logo, 'logos').then((dox) => {
                if (dox.res) {
                    obj.logo = dox.data
                    subirImgEmpresaria(obj)
                } else {
                    cerrarCargador()
                    alert('Ups, algo inesperado ocurrio, intentalo nuevamente\nError: ST01')
                }
            })

        })
    }


    const subirImgEmpresaria = (obj) => {
        subirImagenFile(obj.imgEmpresaria, 'empresarias').then((dox) => {
            if (dox.res) {
                obj.imgEmpresaria = dox.data
                guardarDatosTienda(obj)
            } else {
                cerrarCargador()
                alert('Ups, algo inesperado ocurrio, intentalo nuevamente\nError: ST01')
            }
        })
    }
    const guardarDatosTienda = (obj) => {
        obj.estado = 'En revision'
        obj.fecha = new Date().getTime()
        guardarDoc('revision_tiendas', obj).then((dox) => {
            if (dox.res) {
                setTienda(obj)
                if (cData.usuario) {
                    asignarIdTiendaUsuario(dox.data, obj.logo)
                } else {
                    cerrarCargador()
                    alert('Datos enviados con exito')
                }

                enviarNotificacionAdmins({
                    titulo: 'Nueva Revision de tienda',
                    mensaje: `${cData.usuario.nombre} a enviado su tienda a revision`
                })

            } else {
                alert('Ups, algo inesperado ocurrio, intentalo nuevamente\nError: ST02')
            }
            cerrarCargador()
        })
    }
    const asignarIdTiendaUsuario = (id, logo) => {

        let usuario = cData.usuario
        if (usuario) {
            actualizarDoc('usuarios', usuario.id, {idTienda: id, logo: logo}).then((dox) => {
                if (dox.res) {
                    alert('Datos enviados con exito')

                } else {
                    alert('Ups, algo inesperado ocurrio, intentalo nuevamente\nError: ST03')
                }
                cerrarCargador()
            })
        }

    }


    useEffect(() => {

        if (cData && cData.usuario && cData.usuario.idTienda) {
            let idTienda = cData.usuario.idTienda


            obtenerDoc('revision_tiendas', idTienda).then((dox) => {
                if (dox.res && dox.data) {
                    setTienda(dox.data)
                    setEntidad(dox.data)

                } else {
                    obtenerDoc('tiendas', idTienda).then((des) => {
                        if (des.res) {
                            setTienda(des.data)
                            setEntidad(des.data)
                        } else {

                        }
                    })
                }
            })


        }

    }, [cData]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems={masSM ? 'center' : 'flex-start'}
        >

            <Cargador/>


            <Grid item container lg={9} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                        <Typography sx={{
                            fontSize: 32,
                            fontWeight: 600
                        }}>{tienda && tienda.nombre ? tienda.nombre : 'Tienda vacia'}</Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12}
                          sx={{justifyContent: 'flex-start', px: 0.5, marginTop: -1}}>
                        <Typography sx={{
                            fontSize: 16,
                            fontWeight: 300,
                            color: theme.palette.primary.main
                        }}>{tienda.estado}</Typography>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: 'flex-end'}}>

                <Button
                    onClick={() => irUrl(URLMARKETPLACE + 'Tienda/' + tienda.id)}

                    disabled={tienda.estado !== 'Publicada'} startIcon={<Storefront/>}>Ver en Marketplace</Button>

            </Grid>


            {tienda.estado !== 'Publicada' && tienda.estado !== 'En revision' &&
                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', px: 1, marginTop: 2}}>
                    <Typography
                        sx={{color: theme.palette.primary.main}}>{`**Notas del administrador: ${tienda.comentarios}`}</Typography>
                </Grid>
            }

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 7}}>
                <Formulario_Tienda props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: 'center', px: 1, marginTop: 4, marginBottom: 8}}>
                <Button
                    startIcon={<TaskAlt/>}
                    sx={{py: 1.5, fontSize: 16, px: 4}}
                    onClick={() => guardarCambiosTienda()}
                >Enviar a Revision</Button>
            </Grid>


        </Grid>
    )

}
export default Seccion_Tienda



