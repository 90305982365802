/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {AttachMoney, Inventory2} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {TIPOMONEDA} from "../../Constantes";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import {useIngresarObjeto} from "../../Modulo_formularios/Hooks/useIngresarObjeto";
import Tarjeta_Variante_Producto from "../Tarjetas/Tarjeta_Variante_Producto";
import Formulario_Variante from "./Formulario_Variante";

const Formulario_Producto_Basico = ({...props}) => {
    const {IngresarObjeto} = useIngresarObjeto({
        props: props,
        dato: 'variantes',
        nombre: 'Agregar Variante',
        Tarjeta: Tarjeta_Variante_Producto,
        Formulario: Formulario_Variante
    })


    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-start'}}/>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoImagenCrop dato={'img'} {...props} ancho={1} alto={1} editable={false}/>
                </Grid>

                <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-start'}}/>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory2} nombre={'Nombre del producto'} dato={'nombre'}  {...props}
                                  editable={false}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={AttachMoney} nombre={'Tipo de moneda'} dato={'moneda'}  {...props}
                                   editable={false}
                                   opciones={TIPOMONEDA}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Variantes de Producto</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <IngresarObjeto/>
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Producto_Basico