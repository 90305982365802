/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import {Edit, Layers, ManageSearch} from "@mui/icons-material";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import {usuarioContext} from "../../App";
import Dialogo_Categoria from "../Dialogos/Dialogo_Categoria";
import {useObtenerColleccionTablaWhere} from "../../Servicios/BD/useObtenerColleccionTablaWhere";
import {where} from "firebase/firestore";

const Seccion_Categorias = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const cData = useContext(usuarioContext)
    const [numProductos, setNumProductos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const [wheres, setWheres] = useState([where('idTienda', '==', 'none')])
    const {props, cargarNuevamente} = useObtenerColleccionTablaWhere({
        coleccion: 'categorias',
        filtroInicial: 'fecha',
        wheres: wheres,
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Categoria,
        realizado: () => setRecargar(!recargar)
    })


    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'editar' :
                abrir(row)
                break
        }

    }

    const botones = [
        <Button
            color={'primary'}
            onClick={() => {
                abrir()
            }}
            startIcon={<Layers/>}
            variant={'contained'}>
            Nueva
        </Button>
    ]


    useEffect(() => {

        if (cData && cData.usuario && cData.usuario.idTienda) {
            let idTienda = cData.usuario.idTienda
            contarPropiedad({coleccion: 'categorias', propiedad: 'idTienda', valor: idTienda}).then((dox) => {
                if (dox.res) {
                    setNumProductos(dox.data)
                }
            })

            setWheres([where('idTienda', '==', idTienda)])
        }


        cargarNuevamente()

    }, [recargar, cData]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Categorias Tienda'} textoComplementario={'en lista'}
                                      textoResaltado={`${numProductos} Categorias`} botones={botones}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Categorias

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'right',
        propiedad: 'nombre',

    }
]


const botonesTabla = [
    {
        nombre: 'Editar',
        icono: ManageSearch,
        id: 'editar'
    },

]


