/**************************************************
 * Nombre:       VerificadorTienda
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useContext} from "react";
import {usuarioContext} from "../App";

const VerificadorTienda = ({Componente}) => {
    const cData = useContext(usuarioContext)

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"

        >

            {cData.usuario && cData.usuario.idTienda ?

                <Componente/>

                :

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{px: 4}}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography sx={{fontSize: 32, fontWeight: 600, color: '#00000080'}}>Aun no has creado tu
                                tienda</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                            <Typography sx={{fontSize: 16, fontWeight: 300, color: '#00000080', textAlign: 'cente'}}>El
                                primer paso
                                sera crear tu
                                tienda, dirigete al menu en la seccion tienda</Typography>
                        </Grid>

                    </Grid>
                </Grid>

            }

        </Grid>
    )

}
export default VerificadorTienda    