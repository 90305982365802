/**************************************************
 * Nombre:       VerificadorTienda
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";

const SeccionInhabilitada = ({Componente}) => {


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"

        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{px: 4}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <Typography sx={{fontSize: 32, fontWeight: 600, color: '#00000080'}}>
                            Esta seccion se habilitará muy pronto
                        </Typography>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                        <Typography sx={{fontSize: 16, fontWeight: 300, color: '#00000080', textAlign: 'cente'}}>
                            Estamos realizando ajustes para ti y toda clase de pructos que dees vender
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>


        </Grid>
    )

}
export default SeccionInhabilitada