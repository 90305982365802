/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Delete, TaskAlt} from "@mui/icons-material";
import {useContext, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import Formulario_Producto from "../Formularios/Formulario_Producto";
import {usuarioContext} from "../../App";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";

const Dialogo_Producto = ({realizado, datos, cerrar}) => {
    const cData = useContext(usuarioContext)
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Subiendo datos')
            let obj = entidad
            if (obj.estado) {
                obj.estado = 'En revision'
            }
            obj.idTienda = cData.usuario.idTienda
            obj.fecha = new Date().getTime()
            subirImagenFile(obj.img, 'productos').then((dox) => {
                if (dox.res) {
                    obj.img = dox.data
                    guardarProducto(obj)
                } else {
                    alert('Ups, algo inesperado paso, por favor intentalo nuevamente\nError: #DPE01')
                }
            })

        })


    }

    const guardarProducto = (obj) => {
        guardarDoc('productos', obj).then((dox) => {
            if (dox.res) {
                alert('Datos ingresados con exito, por favor ingrese al menos una variante')
                realizado()
                cerrar()
            } else {
                alert('Ups, algo inesperado paso, por favor intentalo nuevamente\nError: #DPE02')
            }
            cerrarCargador()
        })
    }


    const borrar = () => {
        abrirCargador('Borrando Producto')
        borrarDoc('productos', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Producto props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Producto</Button>
                }
            </Grid>


        </Grid>

    )

}
export default Dialogo_Producto