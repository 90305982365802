/**************************************************
 * Nombre:       Dashboard
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid} from "@mui/material";
import DashboardBase from "../Modulo_dashboard/Componentes/DashboardBase";
import MenuLateral from "../Modulo_dashboard/Componentes/MenuLateral";
import logo from '../Recursos/logo_oscuro.svg'
import logo_blanco from '../Recursos/logo_blanco.svg'
import logo_redondo from '../Recursos/logo_redondo.svg'
import {useContext, useEffect} from "react";
import {usuarioContext} from "../App";
import {funSalir} from "../Servicios/Auth/funSalir";
import {useGetToken} from "../Modulo_Notificaciones/Hooks/useGetToken";
import {useEscucharNotificacion} from "../Modulo_Notificaciones/Hooks/useEscucharNotificacion";
import {
    HandymanOutlined,
    Inventory2Outlined,
    LayersOutlined,
    PointOfSaleOutlined,
    ShoppingCartOutlined,
    Storefront
} from "@mui/icons-material";
import Seccion_Tienda from "./Secciones/Seccion_Tienda";
import {actualizarDoc} from "../Servicios/BD/actualizarDoc";
import VerificadorTienda from "../Utilidades/VerificadorTienda";
import Seccion_Pedidos from "./Secciones/Seccion_Pedidos";
import Seccion_Categorias from "./Secciones/Seccion_Categorias";
import SeccionInhabilitada from "../Utilidades/SeccionInhabilitada";
import Seccion_Catalogo from "./Secciones/Seccion_Catalogo";


const Dashboard = () => {
    const cData = useContext(usuarioContext)
    const {Notificador} = useEscucharNotificacion()
    const {token} = useGetToken()

    const irPerfil = () => {
        // alert('ir Perfil')
    }

    useEffect(() => {
        if (cData.usuario) {
            let usuario = cData.usuario
            if (token && usuario.token !== token) {
                actualizarDoc('usuarios', usuario.id, {token: token}).then((dox) => {
                    if (dox.res) {
                        console.log('Token actualizado')
                    }
                })
            }

        }

    }, [token]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Notificador/>

            <DashboardBase secciones={secciones} usoCredenciales={false}
                           credenciales={cData.usuario.credenciales ? cData.usuario.credenciales : []}>
                <MenuLateral logo={logo} clickPerfil={irPerfil} logoRedondo={logo_redondo} logoBlanco={logo_blanco}
                             usuario={cData.usuario} salir={funSalir}/>
            </DashboardBase>


        </Grid>
    )

}
export default Dashboard

const secciones = [
    {
        nombre: 'Pedidos',
        Componente: <VerificadorTienda Componente={Seccion_Pedidos}/>,
        icono: ShoppingCartOutlined,
        categoria: 'propia',
        camino: '/Potenciales',
    },
    {
        nombre: 'Catalogo',
        Componente: <VerificadorTienda Componente={Seccion_Catalogo}/>,
        icono: Inventory2Outlined,
        categoria: 'tienda',
        camino: '/Catalogo',
    },
    {
        nombre: 'Categorias',
        Componente: <VerificadorTienda Componente={Seccion_Categorias}/>,
        icono: LayersOutlined,
        categoria: 'tienda',
        camino: '/Categorias',
    },
    {
        nombre: 'Tienda',
        Componente: <Seccion_Tienda/>,
        icono: Storefront,
        categoria: 'tienda',
        camino: '/Empresarias',
    },
    {
        nombre: 'Pagos',
        Componente: <SeccionInhabilitada/>,
        icono: PointOfSaleOutlined,
        categoria: 'Administracion',
        camino: '/Pagos',
    }
    ,
    {
        nombre: 'Soporte',
        Componente: <SeccionInhabilitada/>,
        icono: HandymanOutlined,
        categoria: 'Administracion',
        camino: '/Soporte',
    }
    ,

]