/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import Dialogo_Potenciales from "../Dialogos/Dialogo_Potenciales";
import {ESTADOSPEDIDOS} from "../../Constantes";
import {contarPropiedad} from "../../Servicios/BD/Contadores/contarPropiedad";
import {ManageSearch} from "@mui/icons-material";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";

const Seccion_Pedidos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numPedidosNuevos, setNumPedidosNuevos] = useState(0)
    const [numPedidos, setNumPedidos] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'potenciales',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Potenciales,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle de potencial'
    })
    const [cargando, setCargando] = useState(false)


    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'ver' :
                abrir(row)
                break
        }

    }


    useEffect(() => {

        contarPropiedad({coleccion: 'potenciales', propiedad: 'estado', valor: 'Nueva'}).then((dox) => {
            if (dox.res) {
                setNumPedidosNuevos(dox.data)
            }
        })

        contarColeccion({coleccion: 'potenciales'}).then((dox) => {
            if (dox.res) {
                setNumPedidos(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Pedidos'} textoComplementario={'nuevos'}
                                      textoResaltado={`${numPedidosNuevos} Pedidos`}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    estados={ESTADOSPEDIDOS}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numPedidos}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Pedidos

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    },
    {
        nombre: 'Pais',
        direccion: 'right',
        propiedad: 'pais',

    },
    {
        nombre: 'Ciudad',
        direccion: 'right',
        propiedad: 'ciudad',

    }

]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Estado',
        direccion: 'left',
        propiedad: 'estado',

    }
]

const botonesTabla = [
    {
        nombre: 'Ver Detalle',
        icono: ManageSearch,
        id: 'ver'
    },

]



