/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid} from "@mui/material";
import {useFormulario} from "../../Modulo_formularios/Hooks/useFormulario";
import {Delete, TaskAlt} from "@mui/icons-material";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {borrarDoc} from "../../Servicios/BD/borrarDoc";
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {subirImagenFile} from "../../Servicios/Storage/subirImagenFile";
import {useContext} from "react";
import {usuarioContext} from "../../App";
import Formulario_Categoria from "../Formularios/Formulario_Categoria";

const Dialogo_Categoria = ({realizado, datos, cerrar}) => {
    const cData = useContext(usuarioContext)
    const {props, obtenerEntidad} = useFormulario({valoresDefecto: datos})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        obtenerEntidad().then((entidad) => {
            abrirCargador('Guardando datos')
            let obj = entidad
            obj.fecha = new Date().getTime()
            obj.idTienda = cData.usuario.idTienda
            subirImagenFile(entidad.img, 'categorias').then((dox) => {
                if (dox.res) {
                    obj.img = dox.data
                    subirCategoria(obj)
                } else {
                    cerrarCargador()
                    alert('Ups, algo indesperado paso, intentalo nuevamente\nError: #DCGE01')
                }
            })


        })

    }

    const subirCategoria = (obj) => {
        guardarDoc('categorias', obj).then((dox) => {
            if (dox.res) {
                realizado()
                cerrar()
                alert('Cambios guardados con exito')
            } else {
                alert('Ups, algo indesperado paso, intentalo nuevamente\nError: #DCE02')
            }
            cerrarCargador()
        })

    }

    const borrar = () => {
        abrirCargador('Borrando Potencial')
        borrarDoc('potenciales', datos.id).then((dox) => {
            cerrarCargador()
            cerrar()
            realizado()
        })
    }

    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                <Formulario_Categoria props={props}/>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 2}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Guardar Cambios
                </Button>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                {datos && datos.id &&
                    <Button
                        onClick={() => borrar()}
                        variant={'text'}
                        sx={{color: '#00000080', px: 0, marginTop: 2}}
                        startIcon={<Delete/>}
                    >Borrar Categoria</Button>
                }
            </Grid>

        </Grid>

    )

}
export default Dialogo_Categoria