/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {
    AccountCircle,
    Apartment, ArrowRightAlt,
    AttachMoney,
    Flag, Height,
    HomeWork,
    Inventory2,
    Mail, Payment, Payments,
    PhoneAndroid, PriceChange, Upgrade, Scale, CalendarMonth, Warning
} from "@mui/icons-material";
import IngresoSelect from "../../Modulo_formularios/Componentes/IngresoSelect";
import {ESTADOSPOTENCIALES, TIPOMONEDA, TIPOPRODUCTOS} from "../../Constantes";
import {useContext, useEffect, useState} from "react";
import IngresoSelectFuncion from "../../Modulo_formularios/Componentes/IngresoSelectFuncion";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoDinero from "../../Modulo_formularios/Componentes/IngresoDinero";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import {collection, query, where} from "firebase/firestore";
import {fire} from "../../fire";
import {usuarioContext} from "../../App";
import {obtenerColeccionQuery} from "../../Servicios/BD/obtenerColeccionQuery";
import {theme} from "../../Tema";
import {formatoFecha} from "../../Modulo_tablas/Utilidades/formatoFecha";

const Formulario_Producto = ({...props}) => {
    const cData = useContext(usuarioContext)
    const [tipo, setTipo] = useState('')
    const [categoriasGenerales, setCategoriasGenerales] = useState([])
    const [categorias, setCategorias] = useState([])
    const [estado, setEstado] = useState('')
    const [comentario, setComentario] = useState('')
    const [fecha, setFecha] = useState('')


    const obtenerEstadoComentario = () => {
        let valores = props.props.getValues()
        setEstado(valores.estado)
        setComentario(valores.comentarios)
        setFecha(formatoFecha(valores.fecha))
    }

    useEffect(() => {


        obtenerEstadoComentario()


        obtenerCol('categoriasGenerales').then((dox) => {
            if (dox.res) {

                let arr = dox.data.map((it) => it.nombre)
                setCategoriasGenerales(arr)
            }
        })

        console.log(props)

        if ((cData && cData.usuario && cData.usuario.idTienda) || props.difiere) {

            let idTienda = ''

            if (cData.usuario && cData.usuario.idTienda) {
                idTienda = cData.usuario.idTienda
            }

            if (props.difiere) {
                idTienda = props.props.getValues('idTienda')
            }

            console.log(idTienda)

            let q = query(collection(fire, 'categorias'), where('idTienda', '==', idTienda))
            obtenerColeccionQuery(q).then((dox) => {
                if (dox.res) {
                    let arr = dox.data.map((it) => it.nombre)
                    setCategorias(arr)
                }
            })

        }


    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                    <IngresoSelectFuncion Icono={Flag} nombre={'Tipo'} dato={'tipo'}  {...props}
                                          opciones={TIPOPRODUCTOS} setDato={setTipo}
                    />
                </Grid>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>

                            {estado && estado === 'Ajustes pendientes' &&
                                <Warning color={'primary'} sx={{marginTop: -0.5, mr: 0.5, width: 20, height: 20}}/>
                            }
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    lineHeight: 1.1,
                                    fontSize: 14,
                                    color: theme.palette.primary.main
                                }}>
                                {estado ? estado : 'Sin Publicar'}
                            </Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                sx={{fontWeight: 500, lineHeight: 1.1, fontSize: 12}}>
                                {comentario && estado === 'Ajustes pendientes' ? comentario : fecha}
                            </Typography>
                        </Grid>

                    </Grid>


                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'center', marginBottom: -2, marginTop: 2}}>
                    <Typography>Foto producto en uso</Typography>
                </Grid>

                <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-start'}}/>

                <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'flex-start'}}>
                    <IngresoImagenCrop dato={'img'} {...props} ancho={1} alto={1}/>
                </Grid>

                <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: 'flex-start'}}/>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto Icono={Inventory2} nombre={'Nombre del producto'} dato={'nombre'}  {...props}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoTexto nombre={'Descripcion'} dato={'descripcion'}  {...props} lineas={6}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={AttachMoney} nombre={'Tipo de moneda'} dato={'moneda'}  {...props}
                                   opciones={TIPOMONEDA}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Typography>Categoria</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Flag} nombre={'Categorias General'} dato={'categoriaGeneral'}  {...props}
                                   opciones={categoriasGenerales}
                    />
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    <IngresoSelect Icono={Flag} nombre={'Categorias interna'} dato={'categoria'}  {...props}
                                   opciones={categorias}
                    />
                </Grid>

                {tipo === 'Bajo pedido' &&
                    <>
                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography>Tiempo de creacion</Typography>
                        </Grid>

                        <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: 'center'}}>
                            <IngresoTexto Icono={CalendarMonth} nombre={'Tiempo (dias)'} dato={'tiempo'}
                                          type={'number'}  {...props}

                            />
                        </Grid>
                    </>
                }


            </Grid>
        </form>

    )

}
export default Formulario_Producto