/**************************************************
 * Nombre:       Dialogo_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Divider, Grid, Typography} from "@mui/material";
import {Inventory2, TaskAlt} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import {useLoaders} from "../../Modulo_Loaders/Hooks/useLoaders";
import logo from '../../Recursos/logo_redondo_oscuro.png'
import {guardarDoc} from "../../Servicios/BD/guardarDoc";
import {usuarioContext} from "../../App";
import {obtenerDescuento} from "../../Utilidades/obtenerDescuento";
import Tarjeta_Producto_Peque from "../Tarjetas/Tarjeta_Producto_Peque";
import {obtenerVarianteMenorValor} from "../../Utilidades/obtenerVarianteMenorValor";


const Dialogo_Producto_Enviar = ({realizado, datos, cerrar}) => {
    const cData = useContext(usuarioContext)
    const [varianteMenorValor, setVarianteMenorValor] = useState({})
    const {Cargador, abrirCargador, cerrarCargador} = useLoaders({
        logo: logo,
    })


    const guardarCambios = () => {

        let obj = datos
        obj.valor = varianteMenorValor.valor
        obj.rebajado = varianteMenorValor.rebajado

        obj.estado = 'En Revision'
        obj.fecha = new Date().getTime()
        obj.logo = cData.usuario.logo
        obj.descuento = obtenerDescuento({
            valorNormal: varianteMenorValor.valor,
            valorRebajado: varianteMenorValor.rebajado
        })

        abrirCargador('Enviando Datos')

        guardarDoc('productos', obj).then((dox) => {
            if (dox.res) {
                realizado()
                cerrar()
                alert('Cambios guardados con exito\nEl producto entrara en periodo de revision')
            } else {
                alert('Ups, algo inesperado paso, intentalo nuevamente\nError: #DPEE01')
            }
            cerrarCargador()
        })

    }

    const desactivarProducto = () => {

        let obj = datos
        obj.valor = varianteMenorValor.valor
        obj.rebajado = varianteMenorValor.rebajado

        obj.estado = 'Desactivado'
        obj.fecha = new Date().getTime()

        abrirCargador('Enviando Datos')
        guardarDoc('productos', obj).then((dox) => {
            if (dox.res) {
                realizado()
                cerrar()
                alert('Cambios guardados con exito\nEl producto entrara en periodo de revision')
            } else {
                alert('Ups, algo inesperado paso, intentalo nuevamente\nError: #DPEE01')
            }
            cerrarCargador()
        })

    }


    useEffect(() => {

        if (datos && datos.variantes) {
            let variantes = datos.variantes

            let menorValor = obtenerVarianteMenorValor(variantes)

            let obj = {...menorValor}

            obj.img = datos.img
            obj.nombre = datos.nombre

            setVarianteMenorValor(obj)

        }

    }, [datos]);
    return (

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Cargador/>

            <Grid item container lg={7} sm={8} xs={8} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Tarjeta_Producto_Peque item={varianteMenorValor} difiere={true}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Divider sx={{width: '100%'}}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 2}}>
                <Typography sx={{textAlign: 'center', fontSize: 14}}>
                    Al publicar el producto este entrara en periodo de revision antes de ser visible en el marketplace
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    onClick={() => guardarCambios()}
                    startIcon={<TaskAlt/>}
                >
                    Publicar producto
                </Button>
            </Grid>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 4}}>
                <Typography sx={{textAlign: 'center', fontSize: 14}}>
                    Al desactivar el producto este no sera visible en el marketplace hasta que vuelvas a publicarlo
                </Typography>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                <Button
                    disabled={datos !== 'Publicado'}
                    variant={'outlined'}
                    onClick={() => desactivarProducto()}
                    startIcon={<Inventory2/>}
                >
                    Desactivar Producto
                </Button>
            </Grid>


        </Grid>

    )

}
export default Dialogo_Producto_Enviar